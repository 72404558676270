module directives {
    export module integration {
        interface IEDIFACTScopeDirective extends ng.IScope {
            gvwEdiMessages: uiGrid.IGridOptions;
            apiEdiMessages: uiGrid.IGridApi;
            ediMessages: interfaces.integration.IEDIFactMessage[];
            viewEdi(id:number,processedMessage: string, fileExtension: string, fileName: string): void;
            fileName: string;
        }

        export class IEDIFACTDirective implements ng.IDirective {
            templateUrl = `templates/modules/applicationmain/integration/edifactMessageDirectiveView.html`;
            restrict = 'E';
            transclude = true;
            scope = {
                ediMessages: "=",
                fileName: "=?",
                ngDisabled: "=?",
                send: "&",
                validate: "&",
                refresh: "&",
                request: "&?",
                isGoods: "=?",
                isSend: "=?",
                isQueued: "=?",
                isLockedDown: "=?",
                canEdi: "=?",
                disableResend: "=?"
            };

            constructor(public $q: ng.IQService, private generalService: interfaces.applicationcore.IGeneralService, private $timeout: ng.ITimeoutService, private $uibModal: ng.ui.bootstrap.IModalService,
                private bsLoadingOverlayService: interfaces.applicationcore.IbsLoadingOverlayService,private goodsDeclarationService: interfaces.customs.IGoodsDeclarationService,private menuService: interfaces.applicationcore.IMenuService) {

            }

            link = ($scope: IEDIFACTScopeDirective, $element: ng.IAugmentedJQuery) => {
                
                $scope.$watch("ediMessages", (newValue: interfaces.integration.IEDIFactMessage[], oldValue: interfaces.integration.IEDIFactMessage[]) => {
                    $scope.gvwEdiMessages.data = newValue;
                }, true);


                $scope.gvwEdiMessages = {
                    data: [],
                    enableFiltering: false,
                    useExternalFiltering: false,
                    enableCellEdit: false,
                    useExternalSorting: false,
                    multiSelect: false,
                    enableColumnResizing: true,
                    enableFullRowSelection: true,
                    useExternalPagination: false,
                    enableRowHeaderSelection: false,
                    enableHorizontalScrollbar: 2,
                    rowEditWaitInterval: -1,
                    showGridFooter: true,
                    onRegisterApi: (gridApi) => {
                        $scope.apiEdiMessages = gridApi;
                        $scope.gvwEdiMessages.data = $scope.ediMessages;
                    },
                    columnDefs: [{
                        name: "VIEW",
                        field: "Id",
                        displayName: "",
                        enableFiltering: false,
                        width: 34,
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,
                        cellTemplate: `
                    <div class="GridButton"> 
                        <button type="button" class="btn btn-default btn-sm" ng-click="grid.appScope.viewEdi(row.entity.Id,row.entity.ProcessedMessage, row.entity.FileExtension, row.entity.FileName)">
                            <span class="fa fa-eye"></span>
                        </button>
                    </div>`
                    }, {
                        name: "APPREF",
                        displayName: "Application Reference",
                        field: "ApplicationReference",
                        width: 250,
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`
                    }, {
                        name: "SERNUM",
                        displayName: "Serial Number",
                        field: "SerialNumber",
                        width: 150,
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`
                    }, {
                        name: "MSGSTA",
                        displayName: "Message Status",
                        field: "MessageStatus",
                        width: 150,
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`
                    }, {
                        name: "PROCDATE",
                        displayName: "Processed Date",
                        field: "ProcessedStampDate",
                        width: 150,
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,
                        cellFilter: "momentDateFilter: 'YYYY/MM/DD HH:mm'",
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                <gts-date format="yyyy/MM/dd" ng-model="colFilter.term" name="expDate"></gts-date>
                                        
                            </div>`,
                        filter: {
                            condition: function (searchTerm, cellValue) {
                                if (cellValue) {
                                    return cellValue.isSameOrAfter(searchTerm);
                                } else {
                                    return false;
                                }
                            }
                        }
                    }, {
                        name: "CONSERNUM",
                        displayName: "Control Serial Number",
                        field: "ControlSerialNumber",
                        width: 175,
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`
                    }, {
                        name: "CONPROCDATE",
                        displayName: "Control Processed Date",
                        field: "ControlProcessedStampDate",
                        width: 175,
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,
                        cellFilter: "momentDateFilter: 'YYYY/MM/DD HH:mm'",
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                <gts-date format="yyyy/MM/dd" ng-model="colFilter.term" name="expDate"></gts-date>
                                        
                            </div>`,
                        filter: {
                            condition: function (searchTerm, cellValue) {
                                if (cellValue) {
                                    return cellValue.isSameOrAfter(searchTerm);
                                } else {
                                    return false;
                                }
                            }
                        }
                    }, {
                        name: "CONSTAT",
                        displayName: "Control Status",
                        field: "ControlStatusDescription",
                        width: 150,
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`
                    }]
                };

                $scope.viewEdi = (id:number,processedMessage: string, fileExtension: string, fileName: string) => {

                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'con.update.gsd'
                    },
                        () => {
                            return this.goodsDeclarationService.getEDIFactMessageDetail().get({
                                mesId: id
                            }, (result: interfaces.integration.IEDIFactMessage) => {
                                
                                this.showDownloadModal(result.ProcessedMessage, fileExtension ? fileExtension : ".edi", fileName ? fileName : $scope.fileName);
    
                            }, (errorResponse) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                            }).$promise;
                        });

                   
                }

            }

            showDownloadModal(processedMessage: string, fileExtension: string, fileName: string) {
                var modalInstance = this.$uibModal.open({
                    animation: true,
                    backdrop: 'static',
                    template: `
                    <div class="modal-header">
                        <strong class="modal-title">EDI Message</strong>
                    </div>
                    <div class="modal-body">
                        <div class="gts-padding row">
                            <div class="row vertical-align">
                                <div class="col-md-12 col-sm-12">
                                    <textarea id="copy-area" class="form-control" ng-model="processedMessage" rows="25" readonly></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                                    <div class="pull-right">
                                        <button type="button" class="btn btn-primary" ng-click="Copy($event)" ng-if="!isGoods">Copy</button>
                                        <button type="button" class="btn btn-primary" ng-click="Save()">Save</button>
                                        <button type="button" class="btn btn-info" ng-click="Close()">Close</button>
                                    </div>
                    </div>`,
                    controller: ['$uibModalInstance', '$scope', "processedMessage", "fileExtension", "fileName", "$http", "$window", ($modalInstance, $scope, processedMessage: string,
                        fileExtension: string, fileName: string, $http: ng.IHttpService, $window: ng.IWindowService) => {
                        
                        var base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
                        
                        if (base64regex.test(processedMessage)) {
                            $scope.processedMessage = 'Click on Save to download the EDI File'; //base 64 decode required
                        }
                        else {
                            $scope.processedMessage = processedMessage; // no base64 decode required
                        }
                        
                        $scope.isGoods = this.scope.isGoods;

                        function makeblob(dataURL) {
                            const BASE64_MARKER = ';base64,';
                            const parts = dataURL.split(BASE64_MARKER);

                            var type = 'application/octet-stream';

                            const raw = window.atob(parts[0]);
                            const rawLength = raw.length;
                            const uInt8Array = new Uint8Array(rawLength);

                            for (let i = 0; i < rawLength; ++i) {
                                uInt8Array[i] = raw.charCodeAt(i);
                            }

                            return new Blob([uInt8Array], { type: type });
                        }

                        $scope.Save = () => {
                            var filename = fileName + fileExtension;
                            var type = (fileExtension === '.edi' ? 'application/edifact' : 'application/octet-stream');
                            

                            var fileBlob: Blob;
                            if (base64regex.test(processedMessage)) {
                                fileBlob = makeblob(processedMessage); //base 64 decode required
                            }
                            else {
                                fileBlob = new Blob([processedMessage], { type: type }); // no base64 decode required
                            }

                            if (navigator.appVersion.toString().indexOf('.NET') > 0) { // for IE browser
                                window.navigator.msSaveBlob(fileBlob, filename);
                            } else { // for other browsers
                                var pdfDownload = document.createElement("a");
                                document.body.appendChild(pdfDownload);
                                var fileURL = $window.URL.createObjectURL(fileBlob);
                                pdfDownload.href = fileURL;
                                pdfDownload.download = filename;
                                pdfDownload.click();
                                pdfDownload.remove();
                            }
                        }

                        $scope.Copy = ($event: EventTarget) => {
                            try {
                                var textarea = document.getElementById('copy-area');
                                textarea.focus();

                                var successful = document.execCommand('selectAll');
                                if (!successful) throw successful;

                                successful = document.execCommand('copy');
                                if (!successful) throw successful;

                            } catch (err) {
                                window.prompt("Copy to clipboard: Ctrl+C, Enter", $scope.xmlString);
                            }
                        };

                        $scope.Close = () => {
                            $modalInstance.close();
                        };
                    }],
                    resolve: {
                        processedMessage: () => { return processedMessage; },
                        fileExtension: () => { return fileExtension; },
                        fileName: () => { return fileName; }
                    }
                });

                modalInstance.result.then(() => { }, () => { })
            }

            static factory(): ng.IDirectiveFactory {
                const directive = ($q, generalService, $timeout, $uibModal,bsLoadingOverlayService,goodsDeclarationService,menuService) => new IEDIFACTDirective($q, generalService, $timeout, $uibModal,bsLoadingOverlayService,goodsDeclarationService,menuService);
                directive.$inject = ['$q', "generalService", "$timeout", "$uibModal", 'bsLoadingOverlayService','goodsDeclarationService','menuService'];

                return directive;
            }
        }
        angular.module("app").directive("gtsEdi", IEDIFACTDirective.factory());
    }
}